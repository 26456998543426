<template>
<Header/>
    <Banner name="Contact" message="Contact us" />   
    <!-- Contact Map Start -->
    <div class="section section-padding-02">
        <div class="container">

            <!-- Contact Map Wrapper Start -->
            <div class="contact-map-wrapper">
                <iframe id="gmap_canvas"  src="https://maps.google.com/maps?q=1%20Onabule%20Street,%20Ifako%20Bus%20Stop,%20Oworo-Ogudu%20Express%20Way%20Oworo%20Road,%20Kosofe,%20Oworonshoki&t=&z=13&ie=UTF8&iwloc=&output=embed" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
            <!-- Contact Map Wrapper End -->

        </div>
    </div>
    <!-- Contact Map End -->
    <!-- Contact Start -->
    <div class="section section-padding">
    <div class="container">

        <!-- Contact Wrapper Start -->
        <div class="contact-wrapper">
            <div class="row align-items-center">
                <div class="col-lg-6">

                    <!-- Contact Info Start -->
                    <div class="contact-info">
                        <!-- Single Contact Info Start -->
                        <div class="single-contact-info">
                            <div class="info-icon">
                                <i class="flaticon-phone-call"></i>
                            </div>
                            <div class="info-content">
                                <h6 class="title">Phone No.</h6>
                                <p><a :href="'tel:' + phone">{{ phone }}</a></p>
                                <p><a :href="'tel:' + phone">+234 907 3016 305 </a></p>
                            </div>
                        </div>
                        <!-- Single Contact Info End -->
                        <!-- Single Contact Info Start -->
                        <div class="single-contact-info">
                            <div class="info-icon">
                                <i class="flaticon-email"></i>
                            </div>
                            <div class="info-content">
                                <h6 class="title">Email Address.</h6>
                                <p><a  :href="'mailto:' + email">{{ email }}</a></p>
                            </div>
                        </div>
                        <!-- Single Contact Info End -->
                        <!-- Single Contact Info Start -->
                        <div class="single-contact-info">
                            <div class="info-icon">
                                <i class="flaticon-pin"></i>
                            </div>
                            <div class="info-content">
                                <h6 class="title">Office Address.</h6>
                                <p>{{ address }}</p>
                            </div>
                        </div>
                        <!-- Single Contact Info End -->
                    </div>
                    <!-- Contact Info End -->

                </div>
                <div class="col-lg-6">

                    <!-- Contact Form Start -->
                    <div class="contact-form">
                        <h3 class="title">Get in Touch <span>With Us</span></h3>

                        <div class="form-wrapper">
                            <form @submit.prevent="SubmitForm" id="contact-form" >
                                <!-- Single Form Start -->
                                <div class="single-form">
                                    <input type="text" name="name" placeholder="Name" v-model="details.name">
                                </div>
                                <!-- Single Form End -->
                                <!-- Single Form Start -->
                                <div class="single-form">
                                    <input type="email" name="email" placeholder="Email" v-model="details.email">
                                </div>
                                <!-- Single Form End -->
                                <!-- Single Form Start -->
                                <div class="single-form">
                                    <input type="text" name="subject" placeholder="Subject" v-model="details.subject">
                                </div>
                                <!-- Single Form End -->
                                <!-- Single Form Start -->
                                <div class="single-form">
                                    <textarea name="message" placeholder="Message" v-model="details.message"></textarea>
                                </div>
                                <!-- Single Form End -->
                                <p class="form-message"></p>
                                <!-- Single Form Start -->
                                <div class="single-form">
                                    <button class="btn btn-primary btn-hover-dark w-100">Send Message <i class="flaticon-right"></i></button>
                                </div>
                                <!-- Single Form End -->
                            </form>
                        </div>
                    </div>
                    <!-- Contact Form End -->

                </div>
            </div>
        </div>
        <!-- Contact Wrapper End -->

    </div>
    </div>
    <!-- Contact End -->


<Footer/>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Banner from '@/components/Banner.vue'
export default{
    name:"Contact",
    components:{
        Header,Footer,Banner
    },
    data(){
        return{
            details: {
                name: '',
                email: '',
                address: '',
                message: '',
                subject: '',
            },
            phone : "(+234) 09073016297",
            email: "info@bhubcollege.com",
            address: "1 Onabule Street, Ifako Bus Stop, Oworo-Ogudu Express Way Oworo Road, Kosofe, Lagos"
        }
    },
    methods:{
        SubmitForm(){
            alert("Contact just submitted")
        }
    }

}
</script>